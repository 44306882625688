import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Img from 'gatsby-image'
import { Section, SectionTitle, BreadCrumb, MarkdownContent, MarkdownHeading} from "../components/section"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArticleCard from "../components/ArticleList"
import BreakpointUp from "../components/Media/BreakpointUp"
import generateHTML from '../utils/generateHTML';
import Content, { HTMLContent } from '../components/Content';

const ArticleGrid = styled.div`
    display: grid;
    grid-template-rows: auto;
    grid-column-gap: 30px;
    grid-row-gap: 15px;
    grid-template-columns: repeat(1, 1fr);      
    ${BreakpointUp.sm`
      grid-template-columns: repeat(2, 1fr);      
    `}
    ${BreakpointUp.lg`
      grid-template-columns: repeat(3, 1fr);      
    `}
`
const CreatTime = styled.div`
	position: absolute;
	bottom:0;
	left:0;
	width: fit-content;
	background-color:#002337;
	padding: 6px 10px;
	font-size: 12px;
	line-height: 20px;
	color: white;
`

const Article = ({ data }) => {
    const pageData = data.contentfulArticle
    const articles = data.allContentfulArticle
    const PostContent = HTMLContent || Content;
    return(
        <Layout>
            <SEO title={pageData.metaTitle} description={pageData.metaDescription.metaDescription} />
            <Section pt="140px" pb="45px" xpt="110px" xpb="45px" bg="#F4F8FC">     
                <div className="container">
                  <BreadCrumb mb="15px"><Link to='/'>Home</Link>/<span>{pageData.title}</span></BreadCrumb>
                    <MarkdownHeading textAlign="center" maxWidth="890px">
                        <h1>{pageData.title}</h1>
                        <Img fluid={pageData.image.fluid} />
                        <CreatTime>{pageData.createdAt}</CreatTime>                   
                    </MarkdownHeading>
                    <MarkdownContent maxWidth="890px">
                        <PostContent
                          content={generateHTML(pageData.content.childMarkdownRemark.html)}
                        />
                    </MarkdownContent>
                </div>
            </Section>  
            <Section pt="90px" pb="90px"  xpt="60px" xpb="60px" bg="#fff">
              <div className="container">      
                <SectionTitle maxWidth="650px" mb="30px">Explore Our Articles To <br />Help Choosing The Right Building</SectionTitle>     
                <ArticleGrid>
                  {
                    articles.edges.map((item,i) => {
                      if (i < 3) {
                        return(
                          <ArticleCard data={item.node} key={i} />
                        )
                      }
                      return true
                    })
                  }
                </ArticleGrid>
              </div>
            </Section>  
        </Layout>
    )
}
      

export default Article

export const pageQuery = graphql`
  query articleQuery($id: String!) {
    contentfulArticle(id: { eq: $id }) {
      metaTitle
      metaDescription {
        metaDescription
      }
      title
      image {
        fluid {
          aspectRatio
          src
          srcSet
          srcSetWebp
          srcWebp
        }
      }
      createdAt(fromNow: true)
      content {
        childMarkdownRemark {
          html
        }
      }
    }
    allContentfulArticle(sort: {fields: createdAt}) {
      edges {
        node {
          thumbnail {
            fluid {
              base64
              src
              aspectRatio
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          title
          createdAt(fromNow: true)
          content {
            childMarkdownRemark {
              excerpt
            }
          }
          url
        }
      }
    }
  }
`